.text-small {
  font-size: 12px !important;
}

.app-header {
  background-color: #ffffff;
}

/* TUPT ADD 16/04/2020 */

.design-process-section .text-align-center {
  line-height: 25px;
  margin-bottom: 15px;
}

.design-process-content {
  position: relative;
}

.design-process-content h3 {
  margin-bottom: 16px;
}

.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}

.process-model {
  list-style: none;
  padding: 0;
  position: relative;
  margin: 8px 0;
  border: none;
  z-index: 0;
  max-width: 85%;
  margin-left: 7%;
}

.process-model li::after {
  background: #e5e5e5 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  margin: 0 auto;
  position: absolute;
  left: 12%;
  top: 12px;
  width: 75%;
  z-index: -1;
}

.process-model li.visited::after {
  background: #ba242d !important;
}

.process-model li:last-child::after {
  width: 0;
}

.design-process-section .nav.nav-tabs,
.design-process-section .nav.nav-tabs > li > a:hover {
  background: 0 0 !important;
}

.process-model li {
  display: inline-block;
  width: 25%;
  text-align: center;
  float: none;
}

.nav-tabs.process-model > li.active > a,
.nav-tabs.process-model > li.active > a:focus,
.nav-tabs.process-model > li.active > a:hover,
.process-model li a:focus,
.process-model li a:hover {
  border: none;
  background: 0 0;
}

.process-model li a {
  padding: 0;
  border: none !important;
  color: #606060;
}

.process-model li.active,
.process-model li.visited {
  color: #f29f05;
}

.process-model li.active a,
.process-model li.active a:focus,
.process-model li.active a:hover,
.process-model li.visited a,
.process-model li.visited a:focus,
.process-model li.visited a:hover {
  color: #ba242d;
}

.process-model li.active p,
.process-model li.visited p {
  font-weight: 600;
}

.process-model li i {
  font-weight: 700;
  font-style: normal;
  display: block;
  height: 28px;
  width: 28px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  border: 2px solid #e5e5e5;
  font-size: 12px;
  padding: 5px 0;
  color: #000;
  border-radius: 50%;
}

.process-model li.active i,
.process-model li.visited i {
  background: #0b9a0f;
  border-color: #dcd9d9;
  color: #fff;
}

.process-model li.active i {
  background: #f5c335;
}

.process-model li p {
  font-size: 14px;
  margin-top: -49px;
}

.process-model.contact-us-tab li.visited a,
.process-model.contact-us-tab li.visited p {
  color: #606060 !important;
  font-weight: 400;
}

.process-model.contact-us-tab li::after {
  display: none;
}

.process-model.contact-us-tab li.visited i {
  border-color: #e5e5e5;
}

@media screen and (max-width: 560px) {
  .more-icon-preocess.process-model li span {
    font-size: 23px;
    height: 50px;
    line-height: 46px;
    width: 50px;
  }
  .more-icon-preocess.process-model li::after {
    top: 24px;
  }
}

@media screen and (max-width: 380px) {
  .process-model.more-icon-preocess li {
    width: 16%;
  }
  .more-icon-preocess.process-model li span {
    font-size: 16px;
    height: 35px;
    line-height: 32px;
    width: 35px;
  }
  .more-icon-preocess.process-model li p {
    font-size: 8px;
  }
  .more-icon-preocess.process-model li::after {
    top: 18px;
  }
  .process-model.more-icon-preocess {
    text-align: center;
  }
}

.element-scroll {
  overflow-x: hidden;
  border: 1px solid #efefef;
  height: 500px;
  margin-bottom: 12px;
  padding: 4px 9px;
  box-shadow: 0 1px 5px 1px #ccc;
}

.naccs .menu div {
  padding: 15px 20px 15px 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
  font-size: 12px;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.naccs .menu div:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.naccs .menu div span.light {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 24px;
  left: 15px;
  border-radius: 100%;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.naccs .menu div.active span.light {
  background-color: #ba242d;
  left: 0;
  height: 100%;
  width: 3px;
  top: 0;
  border-radius: 0;
}

.naccs .menu div.active {
  color: #da222e;
  padding: 15px 20px 15px 10px;
}

#pointRewardRule ul.dnd-list {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  border-top: 2px solid #ba242d;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}

#pointRewardRule .hd-title {
  border-top: 2px solid #ba242d;
  background: #f0f3f4;
  padding: 9px;
  margin-bottom: 0;
}

#pointRewardRule ul.dnd-list li {
  border: none;
  border-radius: 0;
  padding: 10px 5px;
  border-bottom: 1px dashed #666;
}

#pointRewardRule ul.dnd-list li i {
  float: right;
}

.dragdrop ul[dnd-list] {
  max-height: 364px;
  overflow-y: hidden;
}

.dragdrop ul[dnd-list]:hover {
  overflow-y: auto;
}

.dragdrop ul[dnd-list] li {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
}

ul[dnd-list] li.selected {
  background-color: #dff0d8;
  color: #3c763d;
}

.dragdrop ul[dnd-list] .dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 42px;
}

.warning-content {
  color: #7b5e2a;
  background-color: #f9f9e0;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-style: italic;
}

.radio-condition {
  width: 200px;
}

.radio-condition input[type="radio"] {
  z-index: -1;
}

.radio-condition input[type="radio"]:checked + label {
  background: #007aff;
  color: white;
}

.radio-condition label {
  font-size: 0.875rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: block;
  width: 100%;
  flex: 1 1 auto;
  min-height: 125px;
  padding: 12px 15px;
  height: 100%;
  margin: 0;
  cursor: pointer;
}

/* TUPT ADD 17/04/2020 */

.fieldset-form {
  border: 1px solid #a7a8a8;
  padding: 1%;
  margin: 3%;
  border-radius: 10px;
}

/* END */

.box-frame {
  border: 3px solid #e7eaed;
  padding: 12px;
}

#definitionRule {
  background-color: #f0f3f5;
  border-radius: 3px;
  margin-bottom: 20px;
}

.text-small {
  font-size: 12px !important;
}

.icon-remove {
  color: #ba232d;
}

.color-logo-ayapay {
  font-weight: bold;
  background-color: #ba242d !important;
  border-color: #ba242d;
}

.appnavbarName {
  color: #101010 !important;
}

.data-table {
  background-color: #e9eaed;
}

.rt-resizable-header-content {
  font-weight: bold;
  background-color: #e9eaed;
}

.rthfc-td-fixed-left-last {
  text-align: center;
}

.rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
  background-color: #e9eaed;
}

.ReactTable .rt-thead.-filters .rt-th {
  background-color: #e9eaed;
}

.modal-header {
  background-color: #e9eaed;
}

.rs-picker-daterange {
  width: 100%;
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-picker-toggle-value {
  color: black !important;
}

h4.mb-0 {
  font-size: 21px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial, Helvetica, sans-serif;
}

.nav-link.active {
  font-weight: bold;
}

.fieldset-form {
  border: 1px dashed #a7a8a8;
}

.form-group .col-sm-4 {
  font-weight: bold;
  font-family: Apple-System, Arial, Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", STXihei, sans-serif;
}

.form-group .col-sm-10 {
  font-weight: bold;
  font-family: Apple-System, Arial, Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", STXihei, sans-serif;
}

/* TuPT add 08/06/2020 */

.bg-success,
.bg-warning,
.bg-danger,
.bg-info,
.bg-primary {
  border-radius: 10px;
  width: 65px;
  text-align: center;
  font-weight: bold;
}

.col-md-8 > div > div {
  border: 1px solid #e4e7ea;
}

.rs-progress-line .rs-progress-info {
  border: none;
}

.css-2b097c-container {
  z-index: 2;
}
.footer-card {
  background-color: rgb(200, 206, 211);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 60px;
}
.body-card {
  text-align: center;
  border-top: 1px dashed;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 55px;
}

.p-0{
  padding: 0px !important;
}
.mr-2{
  margin-right: 1em !important;
}
.h-35{
  height: 35px !important;
}
ReactTable .rt-th, .ReactTable .rt-td{
  overflow: visible !important;
}